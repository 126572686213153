.ant-table-tbody>tr>td {
    border-top-color: silver;
    border-right-color: silver !important;
    border-left-color: silver;
    border-bottom-color: silver !important;
    border-bottom-width: 1px !important;
    border-width: 1px;

}

.ant-table-thead>tr>th {

    border-top-color: silver;
    border-right-color: silver !important;
    border-left-color: silver;
    border-bottom-color: silver;
    border-width: 1px;
}

.ant-pagination-next {
    line-height: 1px !important;

}

.ant-pagination-prev {
    line-height: 1px !important;

}